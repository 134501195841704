
import { ref } from 'vue';
import { XCircleIcon } from '@heroicons/vue/solid';
import SystemStatusBadge from '@/components/SystemStatusBadge.vue';
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import { parseRole } from '@/components/utils';
import rolesList from '@/constants/availableRoles';
import { changeUserPassword } from '@/api/users.api';

export default {
  components: {
    SystemStatusBadge,
    XCircleIcon,
  },
  setup(): Record<string, unknown> {
    const onSubmit = async () => {
      try {
        const changePasswordTicket = (await changeUserPassword()).data.ticket;
        window.open(changePasswordTicket, '_blank');
      } catch (e) {
        error.value =
          'Something went wrong retrieving a password change ticket';
      }
    };

    const error = ref('');
    const loading = ref(false);
    const users = ref([]);

    return {
      error,
      loading,
      users,
      rolesList,
      onSubmit,
      parseRole,
    };
  },
};
